<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href=""
        target="_blank"
      >TEAM SEVEN</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
    <span class="float-md-right d-none d-md-block text-primary">
      <b-badge v-for="(authSite, index) in authSite"
               :key="index"
               :variant="buttonVariant(index)"
               style="margin-right: 3px; font-size: xx-small; padding: 8px; width: 5rem"
      >
        {{ authSite }}
      </b-badge>
    </span>
  </p>
</template>

<script>
import { BLink, BBadge, BButton } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
const footerStore = createNamespacedHelpers('footerStore')

export default {
  components: {
    BLink,
    BBadge,
  },
  computed: {
    ...footerStore.mapState({
      sss: 'selectedSiteIndex',
    }),
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteAdminData: null,
      selectedSiteIndex: null,
    }
  },
  mounted() {
  },
  methods: {
    selectSite(index, authSiteSelected) {
      this.$store.commit('footerStore/setSelectedSiteIndex', index)
      this.$store.commit('footerStore/setSelectedSite', authSiteSelected)
      localStorage.setItem('authSiteSelected', authSiteSelected)
    },
    buttonVariant(index) {
      return index === this.sss ? 'success' : 'dark'
    },
  },
}
</script>
